.enroll {
    min-height: 100vh;
    padding-top: 12vh;
}

.enroll .wrapper {
    padding: 3rem 0 2rem 0;
    background-color: var(--color-white);
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

.enroll .container {
    background-color: var(--color-white);
    position: relative;
    padding-bottom: 6rem;
    padding-left: 10%;
    padding-right: 10%;
}

.enroll .container h3 {
    padding: 1.5rem 0 0 0;
    text-transform: uppercase;
}

.enroll .container form .form__group {
    display: flex;
    gap: 4%;
    margin: 1rem 0;
}

.enroll .container form .form__group>div {
    width: 48%;
}

.enroll .container form .form__group .full {
    width: 100%;
}

.enroll .container form .form__group label {
    margin-top: 1rem;
    /* color: var(--light-color); */
}

.enroll .container form .form__group input {
    border: solid var(--grey-light) 1px;
    width: 100%;
    padding: 0.7rem;
    border-radius: 0.3rem;
    background-color: var(--grey-light);
    margin-top: .6rem;
}

.enroll .container form .form__group select {
    border: solid var(--grey-light) 1px;
    width: 100%;
    padding: 0.7rem;
    border-radius: 0.3rem;
    background-color: var(--grey-light);
    margin-top: .6rem;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
    .enroll .container form .form__group {
        flex-direction: column;
    }

    .enroll .container form .form__group>div {
        width: 100%;
    }

    .enroll .container form .form__group input {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}