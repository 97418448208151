.hero {
  min-height: 70vh;
  padding-top: 12rem;
  background-image: url("../../assets/images/div.banner-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero .container {
  display: flex;
}

.hero .container .content {
  width: 30%;
  color: var(--color-white);
}

.hero .container .content .top h1 {
  font-size: 3rem;
  text-transform: capitalize;
}

.hero .container .content .top p {
  width: 100%;
  text-align: left;
}

.hero .container .content .bottom {
  margin-top: 6rem;
}

.hero .container .content .bottom .social {
  display: flex;
  gap: 1rem;
  /* border: solid red 2px; */
}

.hero .container .content .bottom .social .icons {
  font-size: 1.5rem;
  color: var(--light-color);
  transform: var(--transition);
}

.hero .container .content .bottom .social .icons:hover {
  color: var(--color-white);
}

main {
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  background-color: var(--color-white);
  padding-bottom: 8rem;
}

.contact {
  min-height: 100vh;
  position: relative;
  padding-bottom: 8rem;
}

.contact .container {
  display: flex;
  /* border: solid black 2px; */
}

.contact .container .left {
  width: 40%;
  padding-top: 8rem;
  /* border: solid red 2px; */
}

.contact .container .left .txtContent .header h1 {
  font-size: 3rem;
}

.contact .container .left .txtContent {
  padding-right: 4%;
}

.contact .container .left .txtContent .header p {
  width: 100%;
  text-align: left;
  font-size: 1.3rem;
}

.contact .container .left .txtContent .steps {
  padding-right: 4%;
}

.contact .container .left .txtContent .steps .wrapper {
  width: 330px;
  font-size: 14px;
}

.contact .container .left .txtContent .steps .StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}

.contact .container .left .txtContent .steps .StepProgress::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
}

.contact .container .left .txtContent .steps .StepProgress-item {
  position: relative;
  counter-increment: list;
}

.contact
  .container
  .left
  .txtContent
  .steps
  .StepProgress-item:not(:last-child) {
  padding-bottom: 20px;
}

.contact .container .left .txtContent .steps .StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -26px;
  height: 100%;
  width: 10px;
}

.contact .container .left .txtContent .steps .StepProgress-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -37px;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: var(--color-white);
}

.contact
  .container
  .left
  .txtContent
  .steps
  .StepProgress-item.is-done::before {
  border-left: 2px solid var(--black-color);
}

.contact .container .left .txtContent .steps .StepProgress-item.is-done::after {
  content: "✔";
  font-size: 13px;
  color: var(--color-white);
  text-align: center;
  border: 2px solid var(--black-color);
  background-color: var(--black-color);
}

.contact
  .container
  .left
  .txtContent
  .steps
  .StepProgress-item.current::before {
  border-left: 2px solid var(--black-color);
}

.contact .container .left .txtContent .steps .StepProgress-item.current::after {
  content: counter(list);
  padding-top: 1px;
  width: 25px;
  height: 25px;
  top: -4px;
  left: -40px;
  font-size: 14px;
  text-align: center;
  color: var(--black-color);
  border: 2px solid var(--black-color);
  background-color: var(--color-white);
}

.contact .container .left .txtContent .steps .StepProgress strong {
  display: block;
}

.contact .container .right {
  width: 60%;
  /* border: solid red 2px; */
}

.contact .container .right .formsContainer {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  position: absolute;
  top: -12rem;
  width: 49%;
  display: flex;
  flex-direction: column;
  /* border: solid blue 2px; */
}

.contact .container .right .formsContainer .header {
  padding: 3rem 6%;
  display: flex;
  background-color: #f0eff2;
  /* border: solid red 2px; */
}

.contact .container .right .formsContainer .header .icon {
  width: 10%;
  font-size: 3rem;
  /* border: solid red 2px; */
}

.contact .container .right .formsContainer .header .text {
  width: 90%;
}

.contact .container .right .formsContainer .header .text p {
  width: 100%;
  text-align: left;
}

.contact .container .right .formsContainer form {
  margin: 0 6%;
  padding-top: 8rem;
  /* padding-bottom: 3rem; */
  /* border: solid red 2px; */
}

.contact .container .right .formsContainer form .formGroup {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  gap: 5%;
}

.contact .container .right .formsContainer form .formGroup input {
  border: solid #f0eff2 1px;
  width: 80%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: #f0eff2;
}

.contact .container .right .formsContainer form textarea {
  border: solid #f0eff2 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  margin-bottom: 2rem;
  background-color: #f0eff2;
}

.contact .container .right .formsContainer form .alertMsg {
  width: 100%;
  color: rgb(5, 185, 5);
}

.contact .container .right .formsContainer form .btn3 {
  width: 100%;
}

.contact .container .right .formsContainer form .formFooter {
  padding: 1rem 6%;
  /* border: solid red 2px; */
}

.contact .container .right .formsContainer form .formFooter p {
  width: 100%;
  color: var(--light-color);
}

.contact .container .right .formsContainer form .formFooter p a {
  font-weight: 600;
  font-style: oblique;
  color: var(--black-color);
  text-decoration: underline;
}

.address .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.address .container .phone {
  display: flex;
  gap: 10%;
  width: 70%;
  margin-bottom: 4rem;
  justify-content: center;
  align-items: center;
  /* border: solid red 2px; */
}

.address .container .phone div p {
  width: 100%;
  font-size: 1.5rem;
}

.address .container .map {
  filter: saturate(0);
  width: 70%;
}

.address .container .map:hover {
  filter: unset;
}

.address .container .map iframe {
  border-radius: 2rem;
  width: 100%;
  height: 350px;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .hero {
    min-height: 50vh;
  }

  .hero .container .content {
    width: 100%;
  }

  .hero .container .content .bottom {
    margin-top: 2rem;
  }

  .contact {
    padding-bottom: 4rem;
  }

  .contact .container {
    flex-direction: column;
    /* border: solid black 2px; */
  }

  .contact .container .left {
    width: 100%;
    padding-top: 2rem;
    /* border: solid red 2px; */
  }

  .contact .container .left .txtContent .header h1 {
    font-size: 3rem;
  }

  .contact .container .right {
    width: 100%;
    margin-top: 4rem;
    /* border: solid red 2px; */
  }

  .contact .container .right .formsContainer {
    position: relative;
    top: unset;
    width: 100%;

    /* border: solid blue 2px; */
  }

  .contact .container .right .formsContainer .header {
    justify-content: center;
    align-items: center;
  }

  .contact .container .right .formsContainer .header .icon {
    width: 20%;
    font-size: 3rem;
    /* border: solid red 2px; */
  }

  .contact .container .right .formsContainer .header .text {
    width: 80%;
  }

  .contact .container .right .formsContainer .header .text p {
    width: 100%;
    text-align: left;
    font-size: medium;
  }

  .contact .container .right .formsContainer form {
    padding-top: 2rem;
  }

  .contact .container .right .formsContainer form .formGroup {
    flex-direction: column;
    margin-bottom: 0;
  }

  .contact .container .right .formsContainer form .formGroup input {
    width: 100%;
    margin: 1rem 0;
  }

  .contact .container .right .formsContainer form textarea {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  .contact .container .right .formsContainer form .formFooter {
    padding: 1rem 0;
    /* border: solid red 2px; */
  }

  .contact .container .right .formsContainer form .formFooter p {
    text-align: left;
  }

  .address .container .phone {
    flex-direction: column;
    gap: 10%;
    width: 80%;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: solid red 2px; */
  }

  .address .container .phone div p {
    width: 100%;
    font-size: 1.5rem;
  }

  .address .container .map {
    filter: saturate(0);
    width: 100%;
  }

  .address .container .map iframe {
    border-radius: 1rem;
    height: 250px;
  }
}
