.courseWrapper {
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
  width: 100%;
}

.titles {
  /* border: solid red 1px; */
  min-height: 20vh;
  flex: 1;
}
.titles ul {
  /* border: solid blue 1px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 25vh;
}
.title-item {
  cursor: pointer;
  transition: font-weight 0.3s ease;
  padding: 0.5rem;
  color: #fff;
  width: 60%;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(15px);
  margin-bottom: 0.5rem;
}

.title-item.bold {
  font-weight: bold;
  padding: 0.5rem;
  padding-left: 1.5rem;
  background-color: #fff;
  color: #000;
  transition: 0.8s ease;
  width: 80%;
  /* border-bottom-left-radius: 50px;
  border-top-left-radius: 50px; */
}

.details {
  /* border: solid black 1px; */
  min-height: 25vh;
  flex: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detailContent {
  width: 100%;
}
.detailContent p {
  text-align: left;
  width: 100%;
  transition: var(--transition);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .courseWrapper {
    flex-direction: column-reverse;
  }

  .titles ul {
    align-items: center;
    min-height: 25vh;
    /* border: solid red 1px; */
  }

  .details {
    /* border: solid black 1px; */
    min-height: 25vh;
  }
}
