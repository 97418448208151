#portfolio {
    min-height: 100vh;
    padding-top: 12vh;
}

#portfolio .portfolio-container {
    width: 100%;
    height: 90vh;
    background-color: var(--color-white);
    padding: 0 5%;
    position: relative;
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

#portfolio .portfolio-container .portfolio-text-box {
    text-align: center;
    margin-top: 8%;
}

#portfolio .portfolio-container .portfolio-text-box p {
    font-size: 70px;
    font-weight: 600;
    padding: 40px 0;
    color: var(--black-color);
}

#portfolio .portfolio-container .portfolio-text-box h1 {
    font-size: 140px;
    margin-left: -10px;
    line-height: 160px;
    color: transparent;
    -webkit-text-stroke: 2px var(--black-color);
    background: url('../../assets/images/back.png');
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    animation: back 20s linear infinite;
}

@keyframes back {
    100% {
        background-position: 2000px 0;
    }
}


@media screen and (max-width: 800px) {
    #portfolio .portfolio-container .portfolio-text-box p {
        font-size: 50px;
        font-weight: 400;
        padding: 10px 0;
    }

    #portfolio .portfolio-container .portfolio-text-box h1 {
        font-size: 80px;
        margin-left: -10px;
    }
}