@import url("https://cdn.jsdelivr.net/gh/DanielGraham123/bstyles/bstyles.css");

#wizard {
    min-height: 100vh;
    padding-top: 12vh;
}

#wizard .wrapper {
    padding: 3rem 0 2rem 0;
    background-color: var(--color-white);
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

#wizard .container {
    background-color: var(--color-white);
    position: relative;
    padding-bottom: 6rem;
    padding-left: 10%;
    padding-right: 10%;
}

#wizard .container .heading h2 {
    margin-bottom: 3rem;
}

#wizard .container h3 {
    padding: 1.5rem 0 0 0;
    text-transform: uppercase;
}

#wizard .service-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--grey-light);
    border-radius: 1rem;
    padding: 2rem;
    position: relative;
}

#wizard .service-box img {
    width: 100px;
    height: 100px;
}

#wizard .service-box:hover {
    border: 1.5px solid var(--black-color);
    transition: all 0.4s ease;
    scale: 1.1;
}

section#wizard p {
    /* text-align: center; */
    width: 100% !important;
    /* margin: 0.6rem auto 2.5rem; */
}

#wizard .service-check-option {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.service-check-option span:after {
    display: block;
}

/* .service-check-option span:after {
    left: 3px;
    top: 3px;
    content: "";
    width: 17px;
    height: 17px;
    display: none;
    position: absolute;
    border-radius: 100%;
    background-color: var(--black-color);
} */
.service-check-option input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.service-check-option label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.service-check-option label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    background-color: #f0f0f0;
    border: 2px solid #dcdcdc;
    border-radius: 5px;
}

.service-check-option input[type="checkbox"]:checked+label::before {
    background-color: var(--black-color);
    border-color: var(--black-color);
}

.service-check-option label::after {
    content: '';
    position: absolute;
    left: 3px;
    top: 45%;
    width: 11px;
    height: 5px;
    border-left: 2.5px solid #fff;
    border-bottom: 2.5px solid #fff;
    transform: translateY(-50%) rotate(-50deg);
    opacity: 0;
}

.service-check-option input[type="checkbox"]:checked+label::after {
    opacity: 1;
}

#wizard .form-info {
    text-align: left !important;
}

#wizard .form-info .form-group {
    margin-bottom: 1rem;
}

#wizard .react-tel-input .form-control {
    border-radius: unset !important;
    height: unset !important;
    width: 100% !important;
}