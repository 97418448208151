#web-app-solutions {
    padding: 5px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: var(--black-color);
    padding-bottom: 50px;
    min-height: 100vh;
}

#web-app-solutions .web-app-solutions-left-col {
    flex-basis: 50%;
}

#web-app-solutions .web-app-solutions-left-col video {
    width: 100%;
    height: auto;
    padding: 5%;
}

#web-app-solutions .web-app-solutions-right-col {
    flex-basis: 50%;
}

#web-app-solutions .web-app-solutions-right-col .right-col-text-box {
    max-width: 500px;
    margin-right: 100px;
    margin-left: 100px;
    display: inline-block;
    color: var(--color-white);
}

#web-app-solutions .web-app-solutions-right-col .right-col-text-box h1 {
    font-size: 60px;
}

#web-app-solutions .web-app-solutions-right-col .right-col-text-box p {
    margin-top: 60px;
    /* font-weight: 200; */
    padding-bottom: 40px;
    width: 100%;
    text-align: left;
}


#art-design-hub {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100vh;
    background-color: var(--color-white);
}

#art-design-hub .art-design-hub-row {
    display: flex;
    /* flex-direction: row-reverse; */
    align-items: center;
    flex-wrap: wrap;
}

#art-design-hub .art-design-hub-row .art-design-hub-left-col {
    flex-basis: 50%;
}

#art-design-hub .art-design-hub-row .art-design-hub-left-col video {
    width: 100%;
    height: auto;
    padding: 5%;
}

#art-design-hub .art-design-hub-row .art-design-hub-right-col {
    flex-basis: 50%;
}

#art-design-hub .art-design-hub-row .art-design-hub-right-col .art-right-col-text-box {
    max-width: 500px;
    margin-right: 100px;
    margin-left: 100px;
    display: inline-block;
    color: var(--black-color);
}

#art-design-hub .art-design-hub-row .art-design-hub-right-col .art-right-col-text-box h1 {
    font-size: 60px;
}

#art-design-hub .art-design-hub-row .art-design-hub-right-col .art-right-col-text-box p {
    margin-top: 60px;
    /* font-weight: 200; */
    padding-bottom: 40px;
    width: 100%;
    text-align: left;
}


#media {
    padding: 5px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: var(--black-color);
    padding-bottom: 50px;
    min-height: 100vh;
}

#media .media-left-col {
    flex-basis: 50%;
}

#media .media-left-col video {
    width: 100%;
    height: auto;
    padding: 5%;
}

#media .media-right-col {
    flex-basis: 50%;
}

#media .media-right-col .media-right-col-text-box {
    max-width: 500px;
    margin-right: 100px;
    margin-left: 100px;
    display: inline-block;
    color: var(--color-white);
}

#media .media-right-col .media-right-col-text-box h1 {
    font-size: 60px;
}

#media .media-right-col .media-right-col-text-box p {
    margin-top: 60px;
    /* font-weight: 200; */
    padding-bottom: 40px;
    width: 100%;
    text-align: left;
}

#creative-writing {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100vh;
    background-color: var(--color-white);
    position: relative;
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

#creative-writing .creative-writing-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#creative-writing .creative-writing-row .creative-writing-left-col {
    flex-basis: 50%;
}

#creative-writing .creative-writing-row .creative-writing-left-col video {
    width: 100%;
    height: auto;
    padding: 5%;
}

#creative-writing .creative-writing-row .creative-writing-right-col {
    flex-basis: 50%;
}

#creative-writing .creative-writing-row .creative-writing-right-col-text-box {
    max-width: 500px;
    margin-right: 100px;
    margin-left: 100px;
    display: inline-block;
    color: var(--black-color);
}

#creative-writing .creative-writing-row .creative-writing-right-col-text-box h1 {
    font-size: 60px;
}

#creative-writing .creative-writing-row .creative-writing-right-col-text-box p {
    margin-top: 60px;
    /* font-weight: 200; */
    padding-bottom: 40px;
    width: 100%;
    text-align: left;
}




@media screen and (max-width: 800px) {
    #web-app-solutions {
        display: block;
    }

    #web-app-solutions .web-app-solutions-left-col {
        flex-basis: 100%;
    }

    #web-app-solutions .web-app-solutions-right-col {
        flex-basis: 100%;
    }

    #web-app-solutions .web-app-solutions-right-col .right-col-text-box {
        width: 100%;
        margin: 0;
        padding: 5%;
    }

    #web-app-solutions .web-app-solutions-right-col .right-col-text-box h1 {
        font-size: 40px;
    }

    #web-app-solutions .web-app-solutions-right-col .right-col-text-box p {
        font-size: 15px;
        font-weight: 100;
    }

    #art-design-hub .art-design-hub-row .art-design-hub-left-col {
        flex-basis: 100%;
    }

    #art-design-hub .art-design-hub-row .art-design-hub-right-col {
        flex-basis: 100%;
    }

    #art-design-hub .art-design-hub-row .art-design-hub-right-col .art-right-col-text-box {
        width: 100%;
        margin: 0;
        padding: 5%;
    }

    #art-design-hub .art-design-hub-row .art-design-hub-right-col .art-right-col-text-box h1 {
        font-size: 40px;
    }

    #art-design-hub .art-design-hub-row .art-design-hub-right-col .art-right-col-text-box p {
        font-weight: 100;
        font-size: 15px;
    }

    #media .media-left-col {
        flex-basis: 100%;
    }

    #media .media-right-col {
        flex-basis: 100%;
    }

    #media .media-right-col .media-right-col-text-box {
        width: 100%;
        margin: 0;
        padding: 5%;
    }

    #media .media-right-col .media-right-col-text-box h1 {
        font-size: 40px;
    }

    #media .media-right-col .media-right-col-text-box p {
        font-weight: 100;
        font-size: 15px;
    }

    #creative-writing .creative-writing-row .creative-writing-left-col {
        flex-basis: 100%;
    }

    #creative-writing .creative-writing-row .creative-writing-right-col {
        flex-basis: 100%;
    }

    #creative-writing .creative-writing-row .creative-writing-right-col .creative-writing-right-col-text-box {
        width: 100%;
        margin: 0;
        padding: 5%;
    }

    #creative-writing .creative-writing-row .creative-writing-right-col .creative-writing-right-col-text-box h1 {
        font-size: 40px;
    }

    #creative-writing .creative-writing-row .creative-writing-right-col .creative-writing-right-col-text-box p {
        font-weight: 100;
        font-size: 15px;
    }
}