#home-welcome .showcase {
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

#home-welcome .showcase .home_vid_text {
  padding-left: 3rem;
  width: 100%;
  height: auto;
  margin-top: 300px;
  z-index: 2;
  position: absolute;
}

#home-welcome .showcase .home_vid_text h1 {
  font-size: 130px;
  margin-bottom: 30px;
  font-weight: bolder;
  /* width: 50%; */
  word-wrap: break-word;
  color: var(--color-white);
  /* border: solid red 2px; */
}

#hero-bgVid {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

#fluid {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--color-white);
}

#fluid .fluid-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  justify-content: center;
}

#fluid .fluid-row .fluid-left-col {
  flex-basis: 40%;
  margin-right: -300px;
  z-index: 2;
}

#fluid .fluid-row .fluid-left-col img {
  width: 100%;
  height: auto;
}

#fluid .fluid-row .fluid-text-col {
  color: var(--black-color);
  flex-basis: 20%;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
}

#fluid .fluid-row .fluid-text-col h1 {
  font-size: 140px;
}

#fluid .fluid-row .fluid-text-col p {
  margin-top: 20px;
  /* font-weight: 200; */
  padding: 40px;
  width: 100%;
}

#fluid .fluid-row .fluid-text-col a {
  z-index: 5;
}

#fluid .fluid-row .fluid-right-col {
  flex-basis: 40%;
  margin-left: -300px;
  margin-top: -300px;
  z-index: 2;
}

#fluid .fluid-row .fluid-right-col img {
  width: 100%;
  height: auto;
}

#value {
  min-height: 100vh;
  /* position: sticky; */
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: solid red 2px; */
}

#value .value-container {
  position: relative;
  height: 100vh;
  width: 100%;
  padding-bottom: 6rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: solid blue 2px; */
}

#value .value-container .value-text-box {
  width: 100%;
  height: auto;
  margin-top: 100px;
  z-index: 2;
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#value .value-container .value-text-box h1 {
  font-size: 130px;
  /* margin-bottom: 30px; */
  font: Argentum sans;
  font-weight: bolder;
  word-wrap: break-word;
  color: var(--color-white);
  line-height: 7.5rem;
}

#value .value-container .value-text-box p {
  color: var(--color-white);
  padding-top: 35px;
}

#value .value-container .value-bgvid {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

#dream {
  min-height: 100vh;
  background-color: var(--color-white);
  position: relative;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#dream .container .dreamContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#dream .container .dreamContent .top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#dream .container .dreamContent .top .backGroundText {
  position: absolute;
  z-index: 1;
  /* border: solid red 2px; */
}

#dream .container .dreamContent .top .backGroundText h2 {
  font-size: 70px;
}

#dream .container .dreamContent .top .foreGround {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  /* border: solid red 2px; */
}

#dream .container .dreamContent .top .foreGround .centerImage {
  z-index: 2;
  /* border: solid red 2px; */
}

#dream .container .dreamContent .top .foreGround .foreText {
  position: absolute;
  z-index: 3;
  bottom: 5rem;
  /* border: solid blue 2px; */
}

#dream .container .dreamContent .top .foreGround .foreText h1 {
  font-size: 130px;
}

#dream .container .dreamContent .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
}

#ideas {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black-color);
  position: sticky;
  top: 0;
}

#ideas .container .textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
}

#ideas .container .textContent h1 {
  text-align: center;
  font-size: 90px;
}

#ideas .container .textContent a {
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  #dream {
    min-height: 50vh;
  }
  #dream .container .dreamContent .top .backGroundText {
    /* border: solid red 2px; */
    margin-bottom: 6rem;
  }
  #dream .container .dreamContent .top .foreGround .foreText h1 {
    font-size: 100px;
  }
}

@media screen and (max-width: 800px) {
  .showcase {
    height: 800px;
    width: 100%;
  }

  #home-welcome #hero-bgVid {
    position: absolute;
    top: -150px;
    left: -700px;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    z-index: -1;
  }

  #home-welcome #hero-bgVid .home_vid_text {
    padding: 5%;
    width: 100%;
    border: solid red 2px;
  }

  #home-welcome #hero-bgVid .home_vid_text h1 {
    font-size: 60px;
    width: 100%;
    /* color: tomato; */
  }

  #fluid .fluid-row .fluid-left-col {
    flex-basis: 100%;
    width: 100%;
    margin-right: 0px;
    margin-bottom: -150px;
    margin-top: -220px;
    overflow-x: hidden;
  }

  #fluid .fluid-row .fluid-text-col {
    width: 100%;
    flex-basis: 100%;
  }

  #fluid .fluid-row .fluid-text-col h1 {
    font-size: 120px;
  }

  #fluid .fluid-row .fluid-text-col p {
    padding: 40px 10px;
    margin-top: 40px;
  }

  #fluid .fluid-row .fluid-text-col a {
    z-index: 2;
  }

  #fluid .fluid-row .fluid-right-col {
    flex-basis: 100%;
    width: 100%;
    margin-left: -220px;
    margin-top: -550px;
    overflow-x: hidden;
  }

  #fluid .fluid-row .fluid-right-col img {
    width: 100%;
    height: auto;
  }

  .value-container {
    height: 650px;
  }

  .value-text-box {
    padding-left: 0;
    width: 100%;
  }

  .value-text-box h1 {
    font-size: 80px;
  }

  .value-text-box p {
    font-size: 13px;
    font-weight: lighter;
  }

  #dream .container .dreamContent .top .foreGround .foreText h1 {
    font-size: 100px;
  }
}

@media screen and (max-width: 400px) {
  .home_vid_text {
    padding: 5%;
    width: 100%;
    /* border: solid red 2px; */
  }

  .home_vid_text h1 {
    font-size: 60px !important;
    width: 100% !important;
    /* border: solid blue 2px; */
  }

  #value .value-container .value-text-box h1 {
    font-size: 90px;
    /* margin-bottom: 30px; */
  }

  #dream {
    min-height: 55vh;
    background-color: var(--color-white);
    position: relative;
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
  }

  #dream .container .dreamContent .top .backGroundText h2 {
    font-size: 40px;
  }

  #dream .container .dreamContent .top .foreGround .foreText {
    bottom: 1rem;
    /* border: solid blue 2px; */
  }

  #dream .container .dreamContent .top .foreGround .foreText h1 {
    font-size: 50px;
  }
  #fluid .fluid-row .fluid-text-col h1 {
    font-size: 90px;
  }

  #ideas .container .textContent h1 {
    font-size: 70px;
  }
}
