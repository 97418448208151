@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@font-face {
    font-family: Argentum Sans;
    src: url(./components/font/ArgentumSans-Medium.ttf);
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --color-white: #fff;
    --black-color: #000;
    --transition: all 400ms ease;
    --light-color: #999;
    --grey-light: #F0EFF2;
    --dark-color: rgba(var(--black-color), 0.7);
    --container-lg: 82%;
    --container-md: 90%;
    --transition: all 400ms ease;
    --cyan-color: #007f90;
    --light-cyan-color: #00a8c8;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Open Sans', sans-serif, Argentum Sans;
    line-height: 1.5;
    color: var(--dark-color);
    background: var(--black-color);
    user-select: none;
}

.container {
    width: var(--container-lg);
    margin: 0 auto;
}

img {
    display: block;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.2;
}

section h2 {
    text-align: center;
}

section p {
    text-align: center;
    width: 42%;
    margin: 0.6rem auto 2.5rem;

}

p {
    font-weight: 200;
}

.btn-black {
    min-width: 10rem;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;
    width: fit-content;
    padding: 0.75rem 1rem;
    border-radius: 2rem;
    background: #000;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.2rem;
    cursor: pointer;
    transition: all 400ms ease;
}

.btn-black:hover {
    background: lighten(#000, 8%);
    color: #fff;
    box-shadow: 0 1rem 1.6rem rgba(#000, 0.15);
    transform: scale(0.94);
}


.btn-white {
    min-width: 10rem;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;
    width: fit-content;
    padding: 0.75rem 1rem;
    border-radius: 2rem;
    background: #fff;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.2rem;
    cursor: pointer;
    transition: all 400ms ease;
}

.btn-white:hover {
    background: lighten(#fff, 8%);
    color: #000;
    box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
    transform: scale(0.94);
}

.btn3 {
    display: inline-block;
    width: fit-content;
    padding: 0.75rem 1rem;
    border-radius: 2rem;
    background: var(--black-color);
    color: var(--color-white);
    font-weight: 500;
    letter-spacing: 0.2rem;
    cursor: pointer;
    transition: all 400ms ease;
    min-width: 10rem;
    text-align: center;
    text-transform: capitalize;
}

.btn3:hover {
    background: lighten(#000, 8%);
    color: var(--color-white);
    box-shadow: 0 1rem 1.6rem rgba(#000, 0.15);
    transform: scale(0.94);
}

.btn4 {
    display: inline-block;
    width: fit-content;
    padding: 0.75rem 1rem;
    border-radius: 2rem;
    background: var(--color-white);
    color: var(--black-color);
    font-weight: 500;
    letter-spacing: 0.2rem;
    cursor: pointer;
    transition: all 400ms ease;
    min-width: 10rem;
    text-align: center;
    text-transform: capitalize;
}

.btn4:hover {
    background: lighten(#fff, 8%);
    color: var(--black-color);
    box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
    transform: scale(0.94);
}


.btn-link {
    display: inline-block;
    color: var(--cyan-color);
    cursor: pointer;
    text-decoration: underline !important;
}

.btn-link:hover {
    color: var(--light-cyan-color);
    transition: all 400ms ease;
}


/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {
    .container {
        width: 90%;
    }

    section p {
        width: 55%;
    }

}


/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {
    section p {
        width: 90%;
    }

}