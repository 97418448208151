.getInfoBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.getInfoBox {
  background-color: #f9f9f9;
  width: 30%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.inputBox .error {
  color: red;
  width: 100%;
  margin: 0;
}

.getInfoBox .successMessage p {
  color: #000;
  width: 100%;
  margin: 0;
  font-weight: 600;
}

.inputBox input {
  border: solid #ccc 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: var(--grey-light);
  margin-top: 0.6rem;
}

.inputBox select {
  border: solid #ccc 1px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: var(--grey-light);
  margin-top: 0.6rem;
}

.inputBox .btn3 {
  width: 100%;
  border-radius: 3px;
}

.inputBox input[type="submit"] {
  background-color: #000;
}

.progressBar {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBar progress {
  appearance: auto;
  box-sizing: border-box;
  display: inline-block;
  block-size: 0.3em;
  inline-size: 10em;
  vertical-align: -0.2em;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 9px;
}

.progressBar progress::-webkit-progress-bar {
  background-color: #ccc;
  border-radius: 7px;
}

.progressBar progress::-webkit-progress-value {
  background-color: #2d2d2d;
  border-radius: 7px;
}

.progressBar progress::-moz-progress-bar {
  background-color: #2d2d2d;
  border-radius: 7px;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .getInfoBox {
    width: 100%;
  }
}
