.navbar {
    width: 100vw;
    height: 5rem;
    position: fixed;
    z-index: 999;
    display: grid;
    place-items: center;
    transform: var(--transition);
}

.navScroll {
    /* background: var(--color-white); */
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
    width: 80%;
    border-radius: 40px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
    top: 2%;
    transform: var(--transition);
    backdrop-filter: blur(15px);

}

.navbar.navScroll .container .nav__items .nav_item::after {
    content: "";
    width: 0;
    height: 3px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--black-color);
    transition: width 0.3s;
}

.navbar.navScroll .container .nav__items .nav_item:hover::after {
    width: 50%;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .container .nav__items {
    display: flex;
    gap: 3rem;
    align-items: center;

    right: 0 !important;
    flex-basis: 100%;
    justify-content: flex-end;
}

.navbar .container .nav__items .nav_item {
    color: var(--color-white);
    font-weight: 400;
    font-size: 0.9rem;
    text-transform: capitalize;
    position: relative;
}

.navbar.navScroll .container .nav__items .nav_item {
    color: var(--black-color);
}

.navbar .container .nav__items .nav_item::after {
    content: "";
    width: 0;
    height: 3px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--color-white);
    transition: width 0.3s;
}

.navbar .container .nav__items .nav_item:hover::after {
    width: 50%;
}

button {
    display: none;
}

.navbar .container .nav__logo img {
    width: 8rem;
}


/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {

    .navbar {
        position: fixed;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .navScroll {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .navbar.navScroll .container button {
        color: var(--black-color);
    }

    .navbar .container button {
        display: inline-block;
        background: transparent;
        color: var(--color-white);
        font-size: 1.2rem;
        cursor: pointer;
    }




    .navbar .container .nav__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* background: rgba(255, 255, 255, .55); */
        background-color: var(--color-white);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, .37);
        backdrop-filter: blur(100px);
        border-radius: 15px 15px 15px 15px;
        width: 100%;
        height: auto;
        position: absolute;
        left: -100%;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
        top: 6.9rem;
    }

    .navbar .container .show__nav {
        display: flex;
        left: 0%;
    }

    .navbar .container .hide__nav {
        display: none;
    }

    .navbar .container .nav__items .nav_item {
        color: var(--black-color);
    }

    .navbar.container .nav__items .nav_item {
        display: block;
        padding: 2rem 0;
        width: 100%;

    }

    .navbar .container .nav__logo img {
        width: 8rem;
    }
}



/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {

    .navbar .container .nav__logo img {
        width: 6rem;
    }

}