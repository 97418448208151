.bootCampHero {
  min-height: 70vh;
  padding-top: 5rem;
  background-image: url("../../assets/images/div.banner-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bootCampHero .container {
  display: flex;
  justify-content: center;
  align-items: center;

  /* border: solid red 1px; */
}

.bootCampHero .container .left {
  width: 50%;
}

.bootCampHero .container .left .heroTxtContent {
  color: var(--color-white);
}

.bootCampHero .container .left .heroTxtContent h1 {
  font-size: 3rem;
  text-transform: capitalize;
  margin-bottom: 2rem;
}

.bootCampHero .container .left .heroTxtContent p {
  width: 100%;
  text-align: left;
}

.bootCampHero .container .right {
  width: 50%;
}

.school {
  padding-top: 2rem;
}

/* .school h2 {
  text-align: left;
} */

.school .container .aboutSchool {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  /* border: solid red 2px; */
  padding: 1rem 0;
}

.school .container .aboutSchool article {
  width: 80%;
}

.school .container .aboutSchool article p {
  text-align: left;
  width: 100%;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.aboutBanner {
  background-color: #f9f9f9;
  width: 80%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.overview ul {
  padding: 1rem 0;
}

.overview ul li {
  list-style-type: square;
  margin-left: 1.5rem;
  padding: 0.5rem 0;
}

.school .container .courses {
  position: relative;
}

.school .container .courses .course {
  display: flex;
}

.school .container .courses .course .left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.school .container .courses .course .left .txtContent h1 {
  font-size: 3rem;
}

.school .container .courses .course .left .txtContent article p {
  width: 100%;
  text-align: left;
}

.school .container .courses .course .right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.school .container .courses .course .imgWrapper img {
  width: 25rem;
}

.benefits {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  gap: 4%;
}

.benefits .whatYouWillLearn {
  width: 80%;
}

.benefits .whatYouWillLearn .content p {
  width: 100%;
  text-align: left;
  padding: 1rem;
  font-weight: 400;
}

.benefits .whatYouWillLearn .content ul li {
  list-style: disc;
  margin-left: 8%;
}

.benefits .pathWays {
  width: 48%;
}

.school .container .courses .benefits .pathWays .content p {
  width: 100%;
  text-align: left;
}

.school .container .courses .benefits .pathWays .content ul li {
  list-style: disc;
  margin-left: 8%;
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and(max-width:1024px) {
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  .bootCampHero .container {
    flex-direction: column;
  }

  .bootCampHero .container .left {
    width: 100%;
  }

  .bootCampHero .container .left .heroTxtContent h1 {
    font-size: 3rem;
  }

  .bootCampHero .container .right {
    width: 100%;
  }

  .school .container .aboutSchool {
    flex-direction: column;
  }

  .school .container .aboutSchool article {
    width: 100%;
  }

  .aboutBanner {
    width: 100%;
  }

  .school .container .courses .course {
    flex-direction: column;
  }

  .school .container .courses .course .left {
    width: 100%;
  }

  .school .container .courses .course .left .txtContent h1 {
    font-size: 3rem;
  }

  .school .container .courses .course .right {
    width: 100%;
    margin-top: 2rem;
  }

  .school .container .courses .course .right .imgWrapper img {
    width: 90%;
  }

  .school .container .courses .benefits {
    flex-direction: column;
  }

  .benefits .whatYouWillLearn {
    width: 100%;
  }

  .benefits .pathWays {
    width: 100%;
    margin-top: 3rem;
  }
}
