#about-us {
  min-height: 100vh;
  padding-top: 12vh;
}

#about-us .container {
  width: 100%;
  height: 90vh;
  background-image: linear-gradient(rgba(255, 255, 255, 0.3), transparent),
    url("../../assets/images/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 5%;
  position: relative;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: solid blue 2px; */
}

#about-us .container .text-box {
  bottom: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  /* border: solid red 2px; */
}

#about-us .container .text-box img {
  filter: opacity(0.8);
  width: 40%;
}

#about-us .container .text-box h3 {
  margin: auto;
  padding: 10px;
  font-weight: 200;
}

#about-us .container .text-box p {
  font-size: 30px;
  font-weight: 600;
  /* margin-top: 20rem; */
  /* border: solid red 2px; */
}

.coreValues {
  min-height: 100vh;
  background-color: var(--black-color);
  /* padding-top: 4rem; */
  position: relative;
  padding-bottom: 6rem;
}

.coreValues .container {
  padding-top: 4rem;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.coreValues .container .header h1 {
  font-size: 3rem;
  text-transform: capitalize;
}

.coreValues .container .wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url("./../../assets/images/core_values_page.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.coreValues .container .wrapper article {
  color: var(--color-white);
}

.coreValues .container .wrapper article h2 {
  text-transform: uppercase;
  text-align: left;
  /* border: solid red 2px; */
}

.coreValues .container .wrapper .speed {
  width: 15rem;
  position: absolute;
  top: 15%;
  left: 21%;
}

.coreValues .container .wrapper .creativity {
  width: 15rem;
  position: absolute;
  top: 50%;
  left: 15%;
}

.coreValues .container .wrapper .excellence {
  width: 15rem;
  position: absolute;
  right: -8%;
  top: 30%;
}

.coreValues .container .wrapper .affordability {
  width: 15rem;
  position: absolute;
  bottom: 0;
  right: -2%;
}

@media screen and (max-width: 800px) {
  #about-us .container .text-box {
    position: absolute;
    bottom: 20%;
    text-align: center;
    width: 100%;
  }

  #about-us .container .text-box p {
    font-size: 30px;
    font-weight: 600;
    width: 100%;
  }

  #about-us .container .text-box img {
    width: 80%;
  }

  #about-us .container .text-box h3 {
    width: 100%;
    font-size: 15px;
  }
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 1024px) {
  .coreValues {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .coreValues .container {
    flex-direction: column;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .coreValues .container .header h1 {
    font-size: 3rem;
  }

  .coreValues .container .wrapper {
    background-image: unset;
    min-height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: solid red 2px; */
  }

  .coreValues .container .wrapper .speed {
    width: 100%;
    position: unset;
    margin-top: 2rem;
  }

  .coreValues .container .wrapper .creativity {
    width: 100%;
    position: unset;
    margin-top: 2rem;
  }

  .coreValues .container .wrapper .excellence {
    width: 100%;
    position: unset;
    margin-top: 2rem;
  }

  .coreValues .container .wrapper .affordability {
    width: 100%;
    position: unset;
    margin-top: 2rem;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  #about-us .container .text-box {
    text-align: left;
  }

  .coreValues {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .coreValues .container {
    flex-direction: column;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .coreValues .container .header h1 {
    font-size: 3rem;
  }

  .coreValues .container .wrapper {
    background-image: unset;
    min-height: max-content;
  }

  .coreValues .container .wrapper .speed {
    width: 100%;
    position: unset;
    margin-top: 2rem;
  }

  .coreValues .container .wrapper .creativity {
    width: 100%;
    position: unset;
    margin-top: 2rem;
  }

  .coreValues .container .wrapper .excellence {
    width: 100%;
    position: unset;
    margin-top: 2rem;
  }

  .coreValues .container .wrapper .affordability {
    width: 100%;
    position: unset;
    margin-top: 2rem;
  }
}
